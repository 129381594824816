import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import GrundstuekAnbieten from '../components/forms/grundstueck-anbieten'
import Helmet from 'react-helmet'

export const query = graphql`
  query GrundstuekAnbietenPage {
    grundstuekAnbietenPage: sanityPage(slug: { current: { eq: "grundstueck-anbieten" } }) {
      id
      title
    }
  }
`

const GrundstuekAnbietenPage = props => {
  const { data, errors } = props
  const pageData = data && data.grundstuekAnbietenPage

  return (
    <>
      <Helmet>
        <html className="is-bg-beige is-footer-offset" />
      </Helmet>
      {pageData && pageData.title && <SEO title={pageData.title} />}
      <GrundstuekAnbieten />
    </>
  )
}

export default GrundstuekAnbietenPage
